$dark:          #7F7F7F;
$navbar-dark-color: #fff;
$navbar-dark-active-color: #6dcff6;
$navbar-dark-hover-color: #6dcff6;
$navbar-brand-padding-y: .8rem;
$navbar-nav-link-padding-x: 1rem;
$input-btn-focus-box-shadow: none;
$fa-font-path:  "../fonts";
$h1-font-size:  1.8rem;

$primary: #168AC9;
$light: #E9E9E9;
$nav-tabs-border-color: #ADACAC;
$nav-tabs-link-active-border-color: #ADACAC #ADACAC #ffffff;


//Прелоадер, пока сайт грузится

.ploader {
  position: fixed;
  left: 0;
  top: 0;
  right:0;
  bottom:0;
  background: #ADACAC;
  z-index: 1050;
}
.ploader:after {
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);

  width: 48px;
  height: 48px;
  content: '';
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;

  border: solid 8px #ffffff;
  border-left-color: transparent;
  border-radius: 50%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes blink {
  from {
    opacity: .5;
  }
  to {
    opacity: 1;
  }
}
@keyframes blink {
  from {
    opacity: .5;
  }
  to {
    opacity: 1;
  }
}


///конец прелоадера




@import url('https://fonts.googleapis.com/css?family=Montserrat:400|Open+Sans:300,700&subset=cyrillic');

//для импорта обычного css- @import "bower_components/bootstrap/dist/css/bootstrap";
@import 'bower_components/bootstrap/scss/bootstrap';
//@import 'node_modules/@fortawesome/fontawesome-free/scss/fa-brands';
//@import 'node_modules/@fortawesome/fontawesome-free/scss/fa-regular';
//@import 'node_modules/@fortawesome/fontawesome-free/scss/fa-solid';
//@import 'node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import 'quill.core';
@import url('https://ajax.googleapis.com/ajax/libs/jqueryui/1.12.1/themes/base/jquery-ui.css');

@font-face {
  font-family: 'Calibri';
  src: url('../fonts/calibri-webfont.eot');
  src: url('../fonts/calibri-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/calibri-webfont.woff') format('woff'),
  url('../fonts/calibri-webfont.ttf') format('truetype'),
  url('../fonts/calibri-webfont.svg#Calibri') format('svg');
  font-weight: normal;
  font-style: normal;
}

//@font-face {
//  font-family: 'Calibri';
//  src: url('../fonts/calibrib-webfont.eot');
//  src: url('../fonts/calibrib-webfont.eot?#iefix') format('embedded-opentype'),
//  url('../fonts/calibrib-webfont.woff') format('woff'),
//  url('../fonts/calibrib-webfont.ttf') format('truetype'),
//  url('../fonts/calibrib-webfont.svg#Calibri') format('svg');
//  font-weight: bold;
//  font-style: normal;
//}
//@font-face {
//  font-family: 'Calibri';
//  src: url('../fonts/calibrii-webfont.eot');
//  src: url('../fonts/calibrii-webfont.eot?#iefix') format('embedded-opentype'),
//  url('../fonts/calibrii-webfont.woff') format('woff'),
//  url('../fonts/calibrii-webfont.ttf') format('truetype'),
//  url('../fonts/calibrii-webfont.svg#Calibri') format('svg');
//  font-weight: normal;
//  font-style: italic;
//}
//@font-face {
//  font-family: 'Calibri';
//  src: url('../fonts/calibriz-webfont.eot');
//  src: url('../fonts/calibriz-webfont.eot?#iefix') format('embedded-opentype'),
//  url('../fonts/calibriz-webfont.woff') format('woff'),
//  url('../fonts/calibriz-webfont.ttf') format('truetype'),
//  url('../fonts/calibriz-webfont.svg#Calibri') format('svg');
//  font-weight: bold;
//  font-style: italic;
//}

body  {
  -webkit-font-smoothing: subpixel-antialiased! important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
}

//предотвращает прыгание и дергание фиксированных блоков при скроле
.fixed-top, .compare-title, .header-phone {
  -webkit-transform: translateZ(0);
}

body {
  font-family: 'Open Sans', sans-serif;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}
.navbar-nav a {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
}
.header-phone{
  //position: fixed;
  //top:38px;
  z-index: 950;
  background: $gray-800;
}
a.cart-link{
  color: #fff;
  font-size: 15px;
}
a.navbar-brand {
  color:#6dcff6;
}

  main {
    margin-top: -1px;
  }

  /* CUSTOMIZE THE CAROUSEL
  -------------------------------------------------- */

  /* Carousel base class */
  //.carousel {
  //  margin-bottom: 4rem;
  //}
  /* Since positioning the image, we need to help out the caption */
  .carousel-caption {
    bottom: 3rem;
    z-index: 10;
  }

  /* Declare heights because of positioning of img element */
  .carousel-item {
    height: 45rem;
    background: url(../img/bg-carousel.jpg) no-repeat center;
    background-size: cover;
    //background-color: #777;
  }

  .carousel-item > img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 32rem;
  }

  /* MARKETING CONTENT
  -------------------------------------------------- */

  /* Center align the text within the three columns below the carousel */
  .marketing {
    margin: 2rem auto;
  }

  .marketing .col-lg-4 {
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .marketing h2 {
    font-weight: 400;
  }

  .marketing .col-lg-4 p {
    margin-right: .75rem;
    margin-left: .75rem;
  }

  /* Featurettes
  ------------------------- */

  /* Thin out the marketing headings */
  .index-text1 h3, .index-text2 h3 {
    font-weight: 300;
    line-height: 1;
    letter-spacing: -.05rem;
    margin-bottom: 1.5rem;
  }

  /* RESPONSIVE CSS
  -------------------------------------------------- */

  @media (min-width: 40em) {
    /* Bump up size of carousel content */
    .carousel-caption p {
      margin-bottom: 1.25rem;
      font-size: 1.25rem;
      line-height: 1.4;
    }

    .index-text1 h3, .index-text2 h3 {
      font-size: 2rem;
    }
  }

  .cat-container {
    background: #ADACAC;
  }

  .cat-bar div {
    background: #ADACAC;
    padding: 1px;
    text-align: center;
    margin: 5px;
  }

  .cat-bar div:hover, .cat-bar div.active {
    background: #686767;
    transition: 1s all;
    outline: none;
  }

  //.main-cat .cat-container, .main-cat .cat-bar div{
  //  background: #ADACAC;
  //}
  //.main-cat .cat-bar div:hover{
  //  background: #686767;
  //}

  .cat-bar div.w-100 {
    margin: 0;
    padding: 0;
  }

  .cat-bar img {
    height: 100px;
    margin-top: 25px;
  }

  a.cat {
    color: #fff;
    font-size: .9rem;
  }

  // стили для плитки на главной
  .br-20, .bx-20, .br-md-20, .br-lg-20 {
    border: none;
  }

  @include media-breakpoint-up(sm) {
  }

  @include media-breakpoint-up(md) {
    .br-20, .br-md-20 {
      border-right: 20px solid #fff;
    }
    h1, .h1{
      font-size: 2.5rem;
    }

  }

  @include media-breakpoint-up(lg) {
    .bx-20 {
      border-right: 20px solid #fff;
      border-left: 20px solid #fff;
    }
    .br-md-20 {
      border: none;
    }
    .br-lg-20 {
      border-right: 20px solid #fff;
    }

  }

  @include media-breakpoint-up(xl) {
  }

  .container-1 {
    background: url(../img/bg-blue.jpg) no-repeat center;
  }

  .tile-1, .tile-3, .tile-5, .tile-7 {
    background: #e7e7e7 url(../img/bg-car-wash3sm.jpg) no-repeat right;
    background-size: cover;

  }

  .tile-2 {
    background: #e7e7e7 url(../img/pylesos2.png) no-repeat right;
    background-size: contain;

  }

  .tile-1 div, .tile-3 div, .tile-5 div, .tile-7 div {
    padding: 1rem;
  }

  @include media-breakpoint-up(md) {
    .tile-1 {
      background: #e7e7e7 url(../img/bg-car-wash4a.jpg) no-repeat top right;
    }
    .tile-1 div {
      width: 100%
    }
    .tile-3 {
      background: #145AA0 url(../img/24x7.jpg) no-repeat center;
      background-size: cover;
    }
    .tile-5 {
      background: #145AA0 url(../img/teacher2.jpg) no-repeat center;
      background-size: cover
    }
    .tile-7 {
      background: #e7e7e7 url(../img/services2.jpg) no-repeat right bottom;
    }
    .tile-7 div {
      width: 25%;
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
  }

  @include media-breakpoint-up(lg) {
    .tile-1 div, .tile-2 div, .tile-3 div, .tile-4 div, .tile-5 div, .tile-6 div, .tile-7 div {
      z-index: 1;
    }

    .tile-1 div {
      width: 50%;
    }
    .tile-1, .tile-2, .tile-3, .tile-4, .tile-5, .tile-6, .tile-7 {
      position: relative;
      overflow: hidden;
      background: none;
    }
    .tile-1:before, .tile-2:before, .tile-3:before, .tile-4:before, .tile-5:before, .tile-6:before, .tile-7:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: #e7e7e7 url(../img/bg-car-wash3lg.jpg) no-repeat right;
      background-size: cover;
      //-webkit-background-size: 120%;
      //background-size: 120%;
      transform: scale(1, 1);
      transition: transform 3s;
      //z-index: 1;

    }
    .tile-1:hover:before, .tile-2:hover:before, .tile-3:hover:before, .tile-4:hover:before, .tile-5:hover:before, .tile-6:hover:before, .tile-7:hover:before {
      transform: scale(1.1, 1.1);
    }
    .tile-2:before {
      background: #e7e7e7 url(../img/pylesos2.png) no-repeat right;
      background-size: contain;
    }
    .tile-3:before {
      background: url(../img/24x7.jpg) no-repeat center;
      background-size: cover;
    }
    .tile-4:before {
      background: #e7e7e7 url(../img/pomp-bg.png) no-repeat center bottom;
    }
    .tile-5:before {
      background: url(../img/teacher2.jpg) no-repeat center;
      background-size: cover;
    }
    .tile-6:before {
      background: #e7e7e7 url(../img/tel2.jpg) no-repeat left bottom;
    }
    .tile-7:before {
      background: #e7e7e7 url(../img/services2.jpg) no-repeat right bottom;
    }
  }

  @include media-breakpoint-up(xl) {
    .tile-1:before {
      background: #e7e7e7 url(../img/bg-car-wash3.jpg) no-repeat right;
      background-size: cover;
      z-index: 1;
    }

  }

  .tile-4 {
    background: #e7e7e7 url(../img/pomp-bg.png) no-repeat center bottom;
  }

  .tile-6 {
    background: #e7e7e7 url(../img/tel2.jpg) no-repeat left bottom;
  }

  .pb-10, .py-10 {
    padding-bottom: 6rem !important;
  }

  .pt-10, .py-10 {
    padding-top: 6rem !important;
  }

  .pb-20, .py-20 {
    padding-bottom: 12rem !important;
  }

  .pt-20, .py-20 {
    padding-top: 12rem !important;
  }

  footer {
    background-color: #E7E7E7;
    border-top: 2px #A3A3A3 solid;
  }

  #map {
    height: 40rem;
    width: 100%;
  }

  @media (max-width: 1024px) {
    #map {
      height: 25rem;
    }
  }

  .infowindow h3 {
    color: #3B59A1;
    font-size: 1rem;
  }

  .contact-details, .contact-form {
    background: #ffffff;
    padding: 3.4375rem 0;
  }

  .contact-form {
    background: #ebebeb;
  }
  .contact-details-content{
    max-width: 37.5rem;
    float: right;
  }

  .contact-form-content {
    max-width: 37.5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .contact-details-content address {
    border-left: 2px solid #168ac9;
    padding-left: 0.625rem;
    margin-bottom: 1.25rem;
  }

  span.tel {
    white-space: nowrap;
  }

  .contact-form-content input, .contact-form-content textarea {
    border: none !important;
  }

  .cinema-1, .cinema-2 {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 296px;
  }

  .cinema-1:before, .cinema-2:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-image: url(../img/moika1-lg.jpg);
    background-size: contain;
    //-webkit-background-size: 120%;
    //background-size: 120%;
    transform: scale(1.1, 1.1);
    transition: transform 3s;
  }

  .cinema-2:before {
    background-image: url(../img/moika2-lg.jpg);
  }

  div.marketing:hover .cinema:before {
    transform: scale(1, 1);
  }

  .tile-1, .tile-2, .tile-3, .tile-4, .tile-5, .tile-6, .tile-7 {
    display: none;
  }

  .foot, .carousel-caption {
    opacity: 0;

  }

  /// Появляющиеся блоки при скролинге
  @keyframes effect2 {
    0% {
      opacity: 0;
      -webkit-transform: translateY(70px);
      -ms-transform: translateY(70px);
      transform: translateY(70px);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
  }

  @-webkit-keyframes effect2 {
    0% {
      opacity: 0;
      -webkit-transform: translateY(70px);
      -ms-transform: translateY(70px);
      transform: translateY(70px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .effect2 {
    -webkit-animation: 1s 0s forwards effect2;
    animation: 1s 0s forwards effect2;
  }

  /////
  .cat-jombotron {
    background: #E6E6E6;
    //background: url(../img/bg-carousel.jpg) no-repeat center;
    //background-size: cover;
    //border-bottom: 20px solid #fff;
  }

  .product-item a > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .caption > div a {
    color: #000;
    font-size: 1.1rem;
    font-family: 'Calibri', Tahoma, Arial, Helvetica, sans-serif;
  }

  .price, .more-product-price {
    font-family: 'Calibri', Tahoma, Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
  }
  .more-product-price{
    font-size: 1.2rem;
  }

  .comparecheck{
    margin-top:10px;
  }
  #podbor-price-s, #podbor-price-e{
    width: 25%;
    display: inline;
  }


  :focus {
    outline: none;
  }
  .ui-visual-focus {

    box-shadow: none;

  }

  .brandcheck .ui-checkboxradio-label{
    background: #e9e9e9;
    border: 1px solid #e9e9e9;

  }
  .brandcheck .ui-checkboxradio-checked, .ui-button.ui-state-active:hover, .ui-button:active, .ui-button:focus {
    border: 1px solid #ADACAC;
    background: #ADACAC;
    color: #ffffff;

  }
  #slider-range .ui-state-active{
    border: 1px solid #ADACAC;
    background: #ADACAC;
  }
  .brandcheck {
    font-size:0.9rem;
  }
  .btn-custom{
    background: #fff;
    border: 1px solid #CED4DA;
  }
  .perevorot{
    transform: scale(1, -1);
  }
  button svg {
    color: #343A40;
  }

  .thumbnail {
    background-color: #fff;
    width: 100%;
  }
  .thumbnail > img, .thumbnail a > img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    max-height: 500px;
  }
  .more-item{
    padding: 10px;
    font-size: .95rem;
  }
  .prev-slick,
  .next-slick {
    position: absolute;
    top: 30%;
    z-index: 1;
    cursor: pointer;
  }
  .prev-slick {
    left: -2rem;
  }
  .next-slick {
    right: -2rem;
  }
  .prev-slick svg,
  .next-slick svg {
    color: #C0C0C0;
  }
  .slick-dots {
    bottom: -45px !important;
    width: 90% !important;
  }
  .tab-content{
    padding: 2rem 0 0;
  }
  .post {
    //background: #E2E2E2;
    float: left;
    width: 100%;
    padding: 5px 10px;
    border-top: 1px solid #E2E2E2;
    font-family: 'Calibri', Tahoma, Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    color: #666;
  }
  .post .date {
    float: right;
    color: #999;
    font-size: .9rem;
  }
  .post .name {
    float: left;
  }
  .comment {
    margin-bottom: 15px;
    padding-left: 10px;
    clear: both;
  }
  .detail-content, .detail-content2{
    position: relative;
  }
  #myTab, #myTabContent{
    font-family: 'Montserrat', sans-serif;
  }
  .pseudo-link {
    border-bottom-color: -moz-use-text-color;
    border-bottom-style: dashed;
    border-bottom-width: 1px;
    cursor: pointer;
    color: $link-color;
  }
  a.pseudo-link:hover{
    text-decoration: none;
  }
  .compare-title {
    //overflow: hidden;
    //position: fixed;
    //top: 72px;
    background: #fff;
    z-index: 10;
  }

  .roll {
    overflow: hidden;
  }

  .table-data {
    overflow: hidden;
    //margin-top: 260px;
    margin-bottom: 5rem;
  }

  .compare-page table {
    table-layout: fixed;

  }

  .compare-page td {
    text-align: center;
    padding: .5rem;
    border-bottom: 1px solid #dee2e6;
  }

  .compare-page td.colspan {
    text-align: left;
    border: none;
  }
  .compare-page .table-title-item a {
    color: #000;
  }
  .compare-page td span {
    text-transform: uppercase;
    color: #168AC9;
    font-size: .7rem;

  }

  .compare-page .table-title-item{
    float: left;
    text-align: center;
    height: 200px;
    font-size: 0.9rem;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .compare-page .table-compare{
    font-size: 0.9rem;
  }
  .compare-page .table-title-item img{
    height: 50%;
  }
  .compare-page .wrap{
    position: relative;
    cursor: pointer;
  }

  .ar {
    position: absolute;
    top: 60px;
    font-size: 2rem;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background: #fff;
    line-height: 32px;
    border-radius: 50%;
    box-shadow: 0 0 20px 20px #fff;
    color: #168AC9;
  }

  #ar-left {
    left: 0;
  }

  #ar-right {
    right: 0;
  }

  .compare-page .fa-times{
    color: #999;
    position: absolute;
    right: 1rem;
  }
  .cart-table .row.lead {
    border: none;
  }
  .cart-table .row div{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .cart-table .row img {
    max-height: 100px;
  }
  .order-form{
    background: #ebebeb;
    border-top: 1px solid #ccc;
    padding: 3.4375rem 0;
  }
  .example{
    font-size: .9rem;
    color: #89929A;
  }
  .cart-page .custom-control-label::before {
    background: #ccc;
  }
  .cart-page input, .cart-page textarea{
    border: none;
  }
  .spinner{
    width: 20px;
  }
  .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
    border: 1px solid #168AC9;
    background: #168AC9;
  }
  .articles-page .card {
    background: #F2F2F2;
    border: none;
  }
  .articles-page .col .card {
    background: #fff;
  }
  li.page-item{
    margin-bottom: 1rem;
  }
  .container-card-box{
    margin-bottom: 8rem;
  }
  #ajax-loader, .error-message-modal, .success-message-modal {
    display: none;
  }
  .admin-link-table.edit, .admin-link-table.del {
    position: absolute;
    top: 10px;
  }
  .articles-page .admin-link-table.edit,  .articles-page .admin-link-table.del {
    top: auto;
    bottom: 10px !important;
  }
  .admin-link-table.edit {
    left: 30px;
  }
  .admin-link-table.del {
    right: 30px;
  }
  #p-contact-error, .admin-link-table, .slideparamUp, #ajax-loader, #ajax-loader-auth, .ajax-loader-auth, #ajax-loader-ask, #ajax-loader-zakaz, #ajax-loader-EditPass, .slidemenuUp, .concealed, .error, .odinakovoe {
    display: none;
  }
  .product-item:hover .admin-link-table, .card-body:hover .admin-link-table{
    display: block;
  }
  .img-item-box {
    min-height: 12rem;
  }
  #kit ul {
    list-style-type: none;
    padding-left: 0;
  }
  input::placeholder, textarea::placeholder {
    font-style: italic;
    font-size: 1rem;
  }
  .delete_from_cart {
    cursor: pointer;
  }
  tr.order {
    cursor: pointer;
  }
  .ask-img{
    max-height: 200px;
  }
  h4.card-title a{
    text-decoration: none;
  }
  p.anons::after {
    background: #F2F2F2 none repeat scroll 0 0;
    box-shadow: -15px 5px 15px 15px #F2F2F2;
    content: "";
    display: inline-block;
    height: 10px;
    width: 10px;
  }
  .articles-page .breadcrumb {
    background-color: transparent !important;
    padding: .75rem 0 0 0 !important;
    margin:0 !important;
  }
  .articles-page img{
    @include img-fluid;
  }
  a.btn-edit, a.btn-delete{
    background: #E0E0E0;
    color: #6F6F6F !important;
  }
  a.btn-edit:hover{
    background: #E9E9E9;
    color: #168AC9 !important;
  }
  a.btn-delete:hover{
    background: #E9E9E9;
    color: #A71019 !important;
  }
  .call, .fa-cart-plus, .fa-check-circle{
    cursor:pointer;
  }
  blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }
  .old {
    text-decoration: line-through;
  }
  .wrapper-productName{
    min-height: 5rem;
  }
  .cart-btn-box p{
    margin: 0;
  }
  div.old{
    margin-bottom: -.8rem;
  }
  .product-border{
    border: 1px solid #D8DBE0;
    padding:10px;
    width: 100%;
    border-radius: .25rem;
  }
.nav-link, .cart-link {
  padding: .5rem 1rem;
  font-family: 'Montserrat', sans-serif;
}
.cart-link {
  padding: 0;
}
@include media-breakpoint-up(xs) {
  .cart-link {
    padding: 0;
  }
}
@include media-breakpoint-up(lg) {
  .cart-link {
    padding: .5rem 1rem;
  }
}

//.navbar-toggler {
//  padding: 0 0;
//  font-size: 0.9rem;
//}


//#detail-content2.is_stuck{
//  top:100px !important;
//}